import React, { useState } from "react";
import coin from "./../../assets/coin.png";
import palogo from "./assets/palogo.png";
import LoadingIcons from "react-loading-icons";
import axios from "axios";
import useSWR from "swr";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

export default function NftSecurePage() {
  //   const [loading, setLoading] = useState(true);

  //   const handleEvent = () => {
  //     setLoading(false);
  //     setTimeout(function () {
  //       window.location.href = "/Landing";
  //     }, 5000);
  //   };

  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sent, setSent] = useState(false);
  const [showPaypal, setShowPaypal] = useState(false);
  const [publicKey, setPublicKey] = useState("");

  //   const handleEvent = async () => {
  //     setLoading(false);

  //     // Fetch available wallet public keys
  //     const response = await fetch(
  //       "https://api2.ezy3.io/api/users?fields=phoneNumber&filters[phoneNumber][$notNull]&populate[wallet][fields][0]=publicKey",
  //       {
  //         headers: {
  //           Authorization:
  //             "Bearer 7d32b9fd53226ae9f0a04a1771af81421a5e7d5910dade6643098bbbcae60fade6bf79e7314f0e81424c0c40f54bd6fbf46babbe142e22398270ae7c151b69091079a399e93602ee01d0496b43b3e033823c50f9b822dd902c233ed0e6843eaf0b769230e39184b19bacbdd4ceab9370d314b73316f175a33b99f9124d9c7dee",
  //         },
  //       }
  //     ).catch((error) => {
  //       console.error("Error:", error);
  //     });
  //     const data = await response.json();
  //     const publicKey = data[0]?.wallet?.publicKey;

  //     // Update user's phone number with selected public key
  //     const userId = data[0]?.id;
  //     await fetch(`https://api2.ezy3.io/api/users/${userId}`, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization:
  //           "Bearer 7d32b9fd53226ae9f0a04a1771af81421a5e7d5910dade6643098bbbcae60fade6bf79e7314f0e81424c0c40f54bd6fbf46babbe142e22398270ae7c151b69091079a399e93602ee01d0496b43b3e033823c50f9b822dd902c233ed0e6843eaf0b769230e39184b19bacbdd4ceab9370d314b73316f175a33b99f9124d9c7dee",
  //       },
  //       body: JSON.stringify({
  //         phoneNumber,
  //       }),
  //     }).catch((error) => {
  //       console.error("Error:", error);
  //       window.alert("Error: Your phone number has been registered.");
  //     });

  //     await fetch(`https://api2.ezy3.io/api/comment`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization:
  //           "Bearer 7d32b9fd53226ae9f0a04a1771af81421a5e7d5910dade6643098bbbcae60fade6bf79e7314f0e81424c0c40f54bd6fbf46babbe142e22398270ae7c151b69091079a399e93602ee01d0496b43b3e033823c50f9b822dd902c233ed0e6843eaf0b769230e39184b19bacbdd4ceab9370d314b73316f175a33b99f9124d9c7dee",
  //       },
  //       body: JSON.stringify({
  //         user: phoneNumber,
  //         description: publicKey,
  //       }),
  //     }).catch((error) => {
  //       console.error("Error:", error);
  //     });

  //     setSent(true);

  //     // setTimeout(function () {
  //     //   window.location.href = "/Landing";
  //     // }, 5000);
  //   };

  // handles when a payment is confirmed for paypal
  const onApprove = (data, actions) => {
    return actions.order.capture().then(async function (details) {
      const { payer } = details;

      console.log("Billing details", payer);
      setShowPaypal(false);

      await axios.post("https://api2.ezy3.io/api/comment", {
        user: phoneNumber,
        description: publicKey,
      });
    });
  };

  const handleEvent = async () => {
    setLoading(true);

    try {
      // Fetch available wallet public keys
      const response = await axios.get(
        "https://api2.ezy3.io/api/users?fields=phoneNumber&filters[phoneNumber][$notNull]&populate[wallet][fields][0]=publicKey"
      );
      const data = response.data;
      setPublicKey(data[0]?.wallet?.publicKey);

      // Update user's phone number with selected public key
      const userId = data[0]?.id;
      await axios.put(`https://api2.ezy3.io/api/users/${userId}`, {
        // phoneNumber,
        email: `${phoneNumber}@email.com`,
        phoneNumber: phoneNumber,
      });

      setShowPaypal(true);
    } catch (error) {
      console.error(error);
      window.alert("Error: Your phone number has been registered.");
    }

    setSent(true);
  };

  return (
    <section className="container mx-auto mt-[30px] px-[36px]">
      <div className="grid text-center">
        <h1 className="font-semibold text-[30px]">
          Your <span className="font-black">NFT</span> is landing soon...
        </h1>
        <div className="flex justify-center">
          <img src={coin} alt="coin" className="my-10 animate-bounce" />
        </div>
        {/* <MobileInput /> */}
        {!loading ? (
          <div className="flex justify-center gap-2 mt-8">
            <input
              type="text"
              placeholder="xxxx-xxx-xxx"
              defaultValue="+61"
              name="phone"
              id="first-name"
              onChange={(e) => setPhoneNumber(e.target.value)}
              //   value={phoneNumber}
              className="p-2 block rounded-full border-2 py-1.5 text-[#FC0A57] shadow-sm ring-2 ring-inset ring-[#FC0A57] placeholder:text-[#FC0A57] placeholder:text-center "
            />

            <button
              className="rounded-full bg-[#FC0A57] text-white px-8 py-2"
              onClick={handleEvent}
            >
              Enter
            </button>
          </div>
        ) : (
          <div>
            {!showPaypal && (
              <div className="grid justify-center gap-2 mt-2">
                <LoadingIcons.Bars
                  fill="#FC0A57"
                  className="text-md text-xs w-20 h-14"
                />
                <p className="text-[#FC0A57] font-bold">Loading...</p>
              </div>
            )}
          </div>
        )}
        {showPaypal && (
          <div>
            <p className="text-[#FC0A57] font-bold">
              Please complete the payment
            </p>
            <PayPalScriptProvider
              options={{
                "client-id":
                  "ATnue5X6R8RSRJUq851In22raUKMQQTlhYJJcKB-p-xMn5ggUNK9YZsIk4gNolIlx0-B770Cuv0q-hKl",
                currency: "AUD",
                intent: "capture",
                locale: "en_AU",
                // add google pay
              }}
            >
              {" "}
              <PayPalButtons
                className="container mx-auto w-fit"
                createOrder={(data, actions) => {
                  return actions.order
                    .create({
                      purchase_units: [
                        {
                          amount: {
                            currency_code: "AUD",
                            value: "0.50",
                          },
                        },
                      ],
                      application_context: {
                        shipping_preference: "NO_SHIPPING",
                        brand_name: "VIPNFT.FAN",
                        user_action: "PAY_NOW",
                        return_url: "https://vipnft.fan",
                        cancel_url: "https://vipnft.fan",
                        locale: "en-AU",
                      },
                      // payer: {
                      //   email_address: user.email,
                      //   name: {
                      //     given_name: user.given_name,
                      //     surname: user.family_name,
                      //   },
                      // },
                    })
                    .then((orderId) => {
                      // Your code here after create the order
                      return orderId;
                    });
                }}
                // onApprove={function (data, actions) {
                //   return actions.order.capture().then(function () {
                //     // Your code here after capture the order
                //     // get payer email
                //     setData(data);
                //     console.log(data);
                //   });
                // }}
                onApprove={onApprove}
              />
            </PayPalScriptProvider>
          </div>
        )}

        {!showPaypal && !loading && (
          <p className="text-[#0D1638] mt-4 mb-8 text-[16px] opacity-[0.7]">
            Please <span className="font-bold">enter mobile</span> to secure
            your gift. We will never share your info!
          </p>
        )}

        <div className="flex justify-center">
          <img src={palogo} alt="coin" className="animate-bounce" />
        </div>
      </div>
    </section>
  );
}
