import React, { useState } from "react";
import coin from "./../../assets/NFT-landed.gif";
import { FcLock } from "react-icons/fc";
import { GiWineGlass, GiPriceTag } from "react-icons/gi";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function NftLandingPage() {
  let { publicKey } = useParams();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPasswordBox, setShowPasswordBox] = useState(false);

  const handleEvent = async () => {
    setLoading(true);

    try {
      console.log("publicKey", publicKey);
      console.log("password", password);
      // Fetch available wallet public keys
      const response = await axios.get(
        `https://api2.ezy3.io/api/users?filters[wallet][publicKey]=${publicKey}`
      );
      const data = response.data;
      // setPublicKey(data[0]?.wallet?.publicKey);

      // Update user's phone number with selected public key
      const userId = data[0]?.id;
      await axios.put(`https://api2.ezy3.io/api/users/${userId}`, {
        // phoneNumber,
        password: password,
      });

      // setShowPaypal(true);
      window.open(
        "https://vipnft.fan/dashboard/collections/0x049A311D13834888EE13c68Ec070Fa8BA5B25302",
        "_self"
      );
    } catch (error) {
      console.error(error);
      window.alert(error.message);
    }

    // setSent(true);
  };

  return (
    <section className="container mx-auto mt-[30px] px-[48px]">
      <div className="grid text-center">
        <h1 className="font-semibold text-[32px] mb-3 text-[#FC0A57]">
          Congratulations!
        </h1>
        {/* <p className="text-[20px]">
          your <span className="font-semibold">NFT</span> has landed
        </p> */}
        <p className="text-[16px]">
          This one-of-a-kind <span className="font-black">digital asset</span>{" "}
          is your <span className="font-black">souvenir</span> of attendance and{" "}
          <span className="font-black">unlocks benefits</span>.
        </p>
        <div className="flex justify-center">
          <img
            src={coin}
            alt="coin"
            className="my-6 w-11/12 rounded-lg shadow-2xl"
          />
        </div>
        {/* <p className="text-[15px]">
          This is a <span className="font-bold">unique</span> digital reminder
          of your Pacific Airshow 2023 attendance. Unlock the following{" "}
        </p> */}
        {/* <span className="font-bold mt-8 mb-5">Benefits:</span> */}
        {!showPasswordBox ? (
          <div className="text-[16px] mx-auto my-10">
            <div className="flex gap-1">
              <GiPriceTag className="text-[16px] text-yellow-800" />
              <p> 10% Discount on tickets.</p>
            </div>
            <div className="flex gap-1">
              <GiWineGlass className="text-[16px] text-red-800" />
              <p> 1 Free drink per VIPNFT.</p>
            </div>
            <div className="flex gap-1">
              <FcLock className="text-[16px]" />
              <p>
                {" "}
                Upgrade for <span className="font-black">
                  first access{" "}
                </span>{" "}
                rights.
              </p>
            </div>
          </div>
        ) : (
          <div>
            <p className="mt-8 mb-2">
              {" "}
              Create a password to continue to the{" "}
              <span className="font-bold">VIPNFT</span> Dashboard
            </p>
            <input
              type="password"
              placeholder="Enter a new password"
              className="p-2 block rounded-full border-2 py-1.5 text-[#FC0A57] shadow-sm ring-2 ring-inset ring-[#FC0A57] placeholder:text-[#FC0A57] placeholder:text-center mx-auto mb-10 w-full"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        )}
        <button
          className="rounded-full bg-[#FC0A57] text-white px-4 py-2"
          onClick={() => {
            // handleEvent()
            showPasswordBox ? handleEvent() : setShowPasswordBox(true);
          }}
        >
          {showPasswordBox ? "Continue" : "View in VIPNFT"}
        </button>
        <p
          className="my-4 font-semibold text-[#0B07A2]"
          onClick={() =>
            window.open(
              `https://goerli.etherscan.io/address/${publicKey}#nfttransfers`
            )
          }
        >
          View on <span className="underline">Etherscan</span>
        </p>
        <p className="text-[#84868C] cursor-pointer">More</p>
        <div className="flex justify-center cursor-pointer">
          <BsChevronDoubleDown className="text-[#84868C] text-center" />
        </div>
      </div>
    </section>
  );
}
